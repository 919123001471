.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  background-color: #f5f5f5;
}

.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 50%;
  background-color: white;
  color: black;
}

.registerFormSections {
    display: flex;
    flex-direction: row;
}

.registerFormSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 20px;
}

.registerForm img {
  width: 18%;
  margin: 20px 0;
}

.registerForm h5 {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;
}

.registerForm p {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.registerForm a {
  color: rgb(40, 157, 189);
  margin: 0 20px;
}

.registerForm input {
  width: 80%;
  height: 45px;
  border: none;
  background-color: rgb(234, 234, 234);
}

.registerForm button {
  background-color: rgb(32, 32, 32);
  color: white;
  border: none;
  height: 50px;
  border-radius: 0px;
  margin: 10px 0;
  width: 60%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  cursor: pointer;
}

.registerForm button:hover {
  background-color: rgb(200, 200, 200);
}

.registerFormItem {
    display: flex;
    flex-direction: row;
}

.footerForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}