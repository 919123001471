.docu-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  border: none;
  box-shadow: 24px;
  padding: 15px;
  background-color: #fbfbfb;
  border-radius: 15px;
}

.docu-style h2 {
  margin: 0;
}

.docu-style2 {
  width: 600px;
  border: none;
  box-shadow: 24px;
  padding: 15px;
  background-color: #fbfbfb;
  border-radius: 15px;
}

.docu-style2 h2 {
  margin: 0;
}

.button-style-doc {
  padding: 10px 10px;
  margin: 20px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  width: 20%;
}

.button-style-doc:hover {
  background-color: #43425daa;
}

.accordion {
  margin-top: 15px !important;
  margin-right: 15px;
  box-shadow: none !important;
}

.table_risques {
  border: 0.5px solid #43425d;
  box-shadow: none !important;
  margin-top: 10px;
}
