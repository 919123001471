
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #08223d;
    color: white;
    padding: 20px;
  }
  
  .footerLogo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footerLogo img {
    width: 20%;
    margin-right: 20px;
  }
  
  .footerLogo p {
    color: White;
    font-size: 0.90rem;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 16px;
    text-decoration: none;
  }
  
  .footerRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .footerLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .footerLinks a {
    flex: 0 0 auto;
    color: White;
    font-size: 0.90rem;
    font-weight: 600;
    white-space: nowrap;
    margin: 10px 16px;
    text-decoration: none;
  }
  
  .footerLinks a:hover {
    color: #bbbbbb;
  }
  
  .footerIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .footerIcons img {
    width: 60%;
  }
  
  .footerIcons a {
    margin: 0 10px;
  }

  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .footerLogo {
      align-items: center;
      margin-bottom: 20px;
    }
  
    .footerRight {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .footerLinks {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .footerLinks a {
      margin: 0 10px;
    }
  
    .footerIcons {
      margin-top: 20px;
    }
  }