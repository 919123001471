.login {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  background-color: white;
  color: black;
  top: 50%;
}

.headForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  width: 80%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}

.loginForm img {
  width: 18%;
  margin: 20px 0;
}

.loginForm h5 {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;
}

.loginForm p {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.loginForm a {
  color: rgb(40, 157, 189);
  margin: 0 20px;
}

.loginForm input {
  width: 80%;
  height: 45px;
  border: none;
  background-color: rgb(234, 234, 234);
}

.loginForm button {
  background-color: rgb(32, 32, 32);
  color: white;
  border: none;
  height: 50px;
  border-radius: 0px;
  margin: 10px 0;
  width: 80%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  cursor: pointer;
}

.loginForm button:hover {
  background-color: rgb(200, 200, 200);
}

@media only screen and (max-width: 1024px) {
  .loginForm {
    width: 80%;
  }

  .loginForm img {
    width: 25%;
  }

  .loginForm h5 {
    font-size: 1.3rem;
  }

  .loginForm p {
    font-size: 0.9rem;
  }

  .loginForm input {
    width: 90%;
  }

  .loginForm button {
    height: 45px;
    width: 90%;
  }
}

.error {
  color: white;
  font-size: 0.8rem !important;
  margin: 0;
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
}

.success {
  color: white;
  font-size: 0.8rem !important;
  margin: 0;
  background-color: green;
  padding: 5px 10px;
  border-radius: 5px;
}

/* Ajoutez ce CSS à votre fichier login.css ou à tout autre fichier CSS applicable */

/* Style général pour les modaux */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

/* Style pour le contenu des modaux */
.modal form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
}

.modal h2 {
  margin-bottom: 15px;
}

.modal p {
  margin-bottom: 20px;
}

/* Style pour les boutons des modaux */
.modal button {
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:last-child {
  margin-right: 0;
}

.modal button:hover {
  background-color: #f0f0f0;
}

.modal input {
  margin-bottom: 10px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
}