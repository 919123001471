.contactFields1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.contactFields2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .contactFields1 {
    flex-direction: column;
  }

  .contactFields2 {
    flex-direction: column;
  }
  form{
    width:100%;
    /* padding:0 20px; */
  }

  .contactItem input{
     width: -webkit-fill-available !important;
  }
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contactItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px;
}

.contactItem input {
  width: 200px;
  height: 45px;
  margin: 10px 0;
  border: none;
  background-color: rgb(234, 234, 234);
}

.contactItem textarea {
  width: 300px;
  height: 100px;
  margin: 10px 0;
  border: none;
  background-color: rgb(234, 234, 234);
}

.contactSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 768px) {
  .contactSection {
    flex-direction: column;
  }

  .contactInfo{
    text-align: center;
  }
}