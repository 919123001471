.support-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 2%;
  margin-top: 1%;
}

.support-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 50%;
}

.support-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  margin-right: 20px;
}

.support-content button {
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
}

.support-content button:hover {
  background-color: #43425daa;
}

.support-title {
  font-size: 1.5rem;
  margin: 15px 0 0 0;
  font-weight: 400;
}

.style-textarea {
  width: 80%;
  height: 100px;
  border: none;
  background-color: #e4e4e4;
  border-radius: 10px;
  padding: 10px;
  /* margin: 10px; */
}

.support-line {
    width: 60%;
    height: 1px;
    background-color: #cfcfcf;
    margin: 20px 10px;
}