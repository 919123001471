.style-inputfile {
  width: 20%;
}

.style-inputfile fieldset {
  width: 100%;
  height: 100%;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  background-color: #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.style-inputfile fieldset:hover {
  background-color: #d4d4d4;
}

.style-inputfile fieldset:focus {
  outline: none;
}

.style-inputfile fieldset label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}

.style-inputfile fieldset input[type="file"] {
  display: none;
}

.style-inputfile fieldset .file-input-text {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.style-inputfile fieldset .selected-file {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.style-inputfile fieldset .file-preview {
  width: 20%;
  margin-bottom: 10px;
}

.style-inputfile fieldset .file-preview img {
  width: 100%;
  object-fit: contain;
  margin: auto;
}
