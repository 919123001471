.buttons {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}

.importButton {
  background: #474b6f;
  border-radius: 13px;
  width: auto;
  padding: 10px;
  text-align: center;
  color: white;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.importButton:hover {
  background: #5a5f8a;
}

.inputs {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mesures {
  background-color: white;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 60%;
}

.mesures h2 {
  font-size: "25px";
  font-weight: 400;
  margin: 0 !important;
}

.mesures p {
  color: #0eb300;
}

.modal-modal-title {
  color: #474b6f;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h2 {
  margin: 20px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
