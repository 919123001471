.errorPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 80%;
    margin-left: 10%;
}

.errorPage h1 {
    font-size: 3rem;
    font-weight: 500;
    margin: 10px 0;
}

.errorPage p {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px 0;
    text-align: center;
}

.errorPage a {
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    border: none;
    border-radius: 5px;
    background-color: #c2c2c2;
    padding: 10px;
    margin: 10px 0;
}

.errorPage a:hover {
    background-color: #c2c2c2aa;
}

.errorPage img {
    width: 50%;
    margin: 20px;
}