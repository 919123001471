.navbarHome {
  width:100%;
  position: fixed;
  top: 0;
  z-index: 999;
  color: white;
  transition: top 0.3s;
}

.navbarHome ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  /* background-color: #08223d; */
  padding: 5px 0;
  margin: 0;
}

@media (max-width: 850px) {
  .navbarHome {
    display: none;
  }
  .navbarHome li a {
    font-size: 0.8rem;
    color: white;
    text-decoration: none;
  }
  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;
    font-size: 0.8rem;
    padding: 10px;
    cursor: pointer;
  }
  .navbarHome img {
    width: 200px;
  }

  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;

    padding: 10px;
    cursor: pointer;
  }

  .loginButton:hover {
    background-color: #f2b705;
    border: 1px solid #f2b705;
  }

  .navbarHome li a:hover {
    color: #f2b705;
    font-size: 1.5rem;
  }
}

@media (min-width: 851px) and (max-width: 1250px) {
  .navbarHome li a {
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
  }
  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;
    font-size: 1.2rem;
    padding: 10px;
    cursor: pointer;
  }
  .navbarHome img {
    width: 40%;
  }

  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;

    padding: 10px;
    cursor: pointer;
  }

  .loginButton:hover {
    background-color: #f2b705;
    border: 1px solid #f2b705;
  }

  .navbarHome li a:hover {
    color: #f2b705;
    font-size: 1.5rem;
  }

  .navbarHome img {
    width: 200px;
  }
}

@media (min-width: 1251px) {
  .navbarHome li a {
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
  }
  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;
    font-size: 1.5rem;
    padding: 10px;
    cursor: pointer;
  }
  .navbarHome img {
    width: 40%;
  }

  .loginButton {
    border-radius: 20px;
    color: white;
    border-color: white;
    border: 1px solid;
    background: none;

    padding: 10px;
    cursor: pointer;
  }

  .loginButton:hover {
    background-color: #f2b705;
    border: 1px solid #f2b705;
  }

  .navbarHome li a:hover {
    color: #f2b705;
    font-size: 1.5rem;
  }

  .navbarHome img {
    width: 200px;
  }
}
