.affichages-content-tab-right {
    display : flex;
    flex-direction : column;
    align-items: center;
}

.materialcircle {
    margin: 15px 0 15px 0 !important;
}

.button-generate {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
  text-decoration: none;
}

.button-generate:hover {
  background-color: #43425daa;
}