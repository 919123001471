.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-left: 25px;
  margin-bottom: 20px;
  padding: 10px;
}

.profile-title-section {
  margin: 10px 10%;
  font-size: 1rem;
  font-weight: 300;
}

.profile-title-section p {
  font-size: 1.2rem;
}

.profile-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.profile-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 10px 0;
}

.profile-line {
  width: 1px;
  height: 80%;
  background-color: #cfcfcf;
  margin: 0 20px;
}

.style-textfield {
  width: 50%;
  margin: 10px 0 !important;
}

.style-textfield2 {
  width: 20%;
  margin: 10px 0 !important;
}

.profile-title-section img {
  width: 20%;
  border-radius: 10px;
}

.profile-title-section button {
  margin: 10px;
  color: #ffffff;
  background-color: #43425d;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
}

.profile-title-section button:hover {
  background-color: #43425daa;
}

.button-style {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100px;
  padding: 10px;
  height: 40px;
  background-color: #43425d;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  align-self: center;
}

.button-style:hover {
  background-color: #43425daa;
}