.section3 {
 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .section3{
    display:none;
  }
  
  }

.affichages {
  height: 300px;
}

.box3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 20px;
  width: 80%;
  background-color: #cecece;
  padding: 20px;
  margin-top: 3rem;
}

.line {
  width: 5%;
  height: 2px;
  background-color: #969494;
  align-self: center;
  margin-bottom: 1rem;
}

.box3-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nos-plus {
  display: flex;
  flex-direction: row;
}

.nos-plus-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 20px;
}

.nos-plus-items img {
  width: 70%;
}

.affichagesAccueil {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  margin: 0;
}

.affichagesAccueil img {
  width: 20%;
  margin: 0 20px;
}
