.navbar {

  color: white;
  height: 100vh;
  width: 20%;
  display: flex;
  flex-direction: column;
  position: sticky;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .navbar-active {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    color: white;
  }

  .hide {
    display: none;
  }

  .navbar-header-menu {
    position: fixed;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 10px;
    color: rgb(0, 0, 0);
    bottom: 10px;
    left: 10px;
  }
}

.navbar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.navbar-sections {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  /* Ajout de la propriété justify-content */
}

.navbar-sections a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 20px 0;
}

.navbar-sections a i {
  margin-right: 10px;
}

.navbar-sections a span {
  margin-left: auto;
  /* Ajout de la propriété margin-left:auto pour déplacer les icônes complètement à droite */
}

.navbar-sections a:hover {
  color: #f2b705;
}

.active {
  color: #f2b705 !important;
}

.sub-menu {
  margin-left: 20px;
}

.navbar-dropdown {
  display: none;
}

.navbar-dropdown-active {
  display: block;
  animation: slideDown 1s ease forwards;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hover_button {
  cursor: pointer;
}

.hover_button:hover {
  background-color: #43425daa !important;
}