/* CSS FOR HOME */

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

/* SECTION 1 */

.subtitle{
  max-width: 60%;
}
@media (max-width: 870px) {

  .section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../../images/back.jpg");
    background-size: cover;
  }
  
  .section1 h2 {
    font-size: 1.7rem;
    color: #fff;
    font-weight: 400;
  }
  
  .section1 h3 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
  }
  }

@media (min-width: 871px) and (max-width: 1250px) {

  .section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../../images/back.jpg");
    background-size: cover;
  }
  
  .section1 h2 {
    font-size: 2.5rem;
    color: #fff;
    font-weight: 400;
  }
  
  .section1 h3 {
    font-size: 2.1rem;
    color: #fff;
    font-weight: 400;
    text-align: center;
margin-bottom: 0;
  }
  }

@media (min-width: 1251px) {

.section1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../../images/back.jpg");
  background-size: cover;
}

.section1 h2 {
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
}

.section1 h3 {
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
}
}

.section2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  margin-top: 2rem;
}

.section4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: black;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.section7{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #eeeeee;
  color: black;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section4 img {
  width: 60%;
  margin: 20px 0;
}

.section5 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  color: black;
  margin: 0px 20px;
  margin-top: 2rem;
}


.confiance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #08223d;
  color: white;
}
@media (max-width: 768px) {
  .confiance,
  .confiance .center {
    padding:30px 0;
    flex-direction: column;
    gap:20px;
  }
}
.item {
  margin-right: 0 !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.caroussel {
  display: flex !important;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
  background-color: #08223d;
  color: white;
  padding: 0;
  width: 100% !important;
}

.caroussel img {
  height: 100px;
  margin: '10px';
  display: flex;
  align-items: center;

}

.center {
  display: flex !important;
  width:100%;
  justify-content: space-around;
  align-items: center;
}

.center2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.text-legal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  margin-bottom: 2rem;
  margin-left: 20px;
}

.text-legal h1 {
  font-size: 2rem;
  color: #08223d;
  font-weight: 400;
  margin-bottom: 1rem;
}

.line-accueil {
  width: 5%;
  height: 5px;
  background-color: #727272;
  margin-bottom: 1rem;
}