@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.sectionAboutUs {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #08223d;
}

.sectionAboutUsTitre{
    font-size: 1.5rem;
    font-weight: bold;
}

.sectionAboutUsSousTitre{
    font-size: 1.5rem;
    font-weight: bold;
}

.sectionAboutUsSousTitreLogo{
    width: 175px;
    margin-top: 1rem;

}


@media (max-width: 768px) {
    .sectionAboutUsTableau { flex-direction: column !important;}
}
.sectionAboutUsTableau { 
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2rem;
    font-size: 1.1rem;
}

.sectionAboutUsTableauImg { 
    width: 50%;
    height: 100%;
    border-radius: 10px;
    margin-left: 0.5rem;
}

.sectionAboutUsTableTitres { 
    font-weight: 600;
    font-size: 1.7rem;
}

.sectionAboutUsTableTextes {
    margin-left: 1rem;
    text-align: center;
}

@media (max-width: 768px) {
    .sectionAboutUsTableTextes th,
    .sectionAboutUsTableTextes td {
        padding:5px;
        margin:0;
      display: block;
      width: 100%;
    }

    .sectionAboutUsTitre,
    .sectionAboutUsSousTitre {
        text-align: center;
    }

    .sectionAboutUsTableau {
        align-items: center;
    }
  }