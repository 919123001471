.box-kpi {
  background: #FFFFFF;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 25%;
}

.box-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

img {
  margin-right: 10px;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

h2 {
  font-size: 2rem;
  margin: 0 0 0 0;
  font-weight: 400;
}
