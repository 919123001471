.user-dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.user-dashboard-right {
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.user-dashboard-right-card1 {
  width: 100%;
  overflow: visible !important;
}

.inrsCaroussel {
  width: 300px;
}

.user-dashboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.user-dashboard-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}

.user-dashboard-right span {
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: 500;
}

.user-dashboard-right h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.user-dashboard-right-card {
  width: 100%;
  margin: 2px;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}

.user-dashboard-regle-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-left: 25px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  overflow: visible !important;
}

.user-dashboard-regle-card h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0;
}

.user-dashboard-regle-card-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-dashboard-news-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-left: 25px;
  margin-bottom: 20px;
  padding: 10px;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  overflow: visible !important;
}
