.celluleTitre {
    margin: 0!important;
    padding: 0 5px 0 0!important;
    font-weight: bold!important;
    text-align: center!important;
}

.unitehead {
    background-color: #6b5b95!important;
    border: 8px solid #6b5b95!important;
    color: white!important;
    min-width: 100px!important;
    max-width: 100px!important;
}

.unitecontent {
    min-width: 100px!important;
    max-width: 100px!important;
    padding: 0 13px 0 0!important;
    vertical-align: middle!important;
    text-align: center!important;
}

.uniteencadrement {
    border-bottom: 8px solid #6b5b95!important;
    border-left: 8px solid #6b5b95!important;
    
    width: 100% !important;
}

.risquehead {
    min-width: 120px!important;
    max-width: 120px!important;
    padding: 0 13px 0 0!important;
    background-color: #878f99!important;
    border: 8px solid #878f99!important;
}

.risqueencadrement {
    border-bottom: 4px solid #878f99!important;
    border-left: 4px solid #878f99!important;
    display: flex!important;
}

.risquecontent {
    min-width: 120px!important;
    max-width: 120px!important;
    padding: 0 16px 0 0!important;
    vertical-align: middle!important;
    text-align: center!important;
}

.descriptionhead {
    min-width: 150px!important;
    max-width: 150px!important;
    background-color: #b2ad7f!important;
    border: 8px solid #b2ad7f!important;
}

.descriptionencadrement {
    border-bottom: 4px solid #b2ad7f!important;
    border-left: 4px solid #b2ad7f!important;
    height: 100%!important;
    vertical-align: middle!important;
    
}

.descriptioncontent {
    min-width: 150px!important;
    max-width: 150px!important;
    padding: 0 11px 0 0!important;
    vertical-align: middle!important;
    text-align: center!important;
    height: 100%!important;
}

.frequencehead {
    max-width: 50px!important;
    min-width: 50px!important;
    padding: 0!important;
    background-color: #b2ad7f!important;
    border-top: 8px solid #b2ad7f!important;
    border-bottom: 8px solid #b2ad7f!important;
}

.frequencecontent {
    min-width: 50px!important;
    max-width: 50px!important;
    padding: 0 0 0 0!important;
    vertical-align: middle!important;
    text-align: center!important;
    height: 100%!important;
}

.mesurehead {
    max-width: 220px!important;
    min-width: 220px!important;
    background-color: #a2b9bc!important;
    border-top: 8px solid #a2b9bc!important;
    border-bottom: 8px solid #a2b9bc!important;
}

.mesurecontent {
    min-width: 220px!important;
    max-width: 220px!important;
    padding: 0 5px 0 0!important;
    vertical-align: center!important;
    text-align: left!important;
    height: 100%!important;
}

.specificitehead {
    max-width: 150px!important;
    min-width: 150px!important;
    background-color: #a2b9bc!important;
    border-top: 8px solid #a2b9bc!important;
    border-bottom: 8px solid #a2b9bc!important;
    font-style: italic!important;
}

.specificitecontent {
    min-width: 150px!important;
    max-width: 150px!important;
    padding: 0 4px 0 0!important;
    vertical-align: center!important;
    text-align: left!important;
    font-style: italic!important;
    height: 100%!important;
    background-color: #e8eaec;
}

.boutonmiseajour {
    padding: 10px 20px!important;
    border-radius: 10px!important;
    border: none!important;
    background-color: #43425d!important;
    color:white!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    cursor: pointer!important;
    width: 20%;
    margin: 20px;
}

.ButtonsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DUbuttons {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #43425d;
    color:white;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    margin: 0px 20px;
    height: 50%;
}

.DUbuttons:hover {
    background-color: #43425daa;
}

.pageStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    height: 100%;
    padding:20mm;
    font-family: Arial;
}

.logoStyle {
    width: 150px;
    height: 150px;
    margin: 10px;
}

.titleStyle {
    font-size: 24pt;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
}

.subtitleStyle {
    font-size: 18pt;
    text-align: center;
    margin: 10px 0;
}

.smallTextStyle {
    font-size: 10pt;
    text-align: center;
    margin: 10px 0;
}

.footerStyle {
    font-size: 8pt;
    text-align: center;
    margin: 10px 0;
    
}

#elementFixe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
  }

  .print-button {
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 9999;
    width: 300px;
    height: 100px;
    
  }
  
  @media print {
    .print-button {
      display: none; /* Masque le bouton lors de l'impression */
    }
  }
