.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  padding: 16px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.header-title {
  font-weight: bold;
  margin-right: auto;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.54);
}

.user-dropdown {
  position: relative;
}

.user-dropdown i {
  margin-left: 10px;
}

.user-dropdown-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  font-size: 1rem;
}

.user-dropdown-button:focus {
  outline: none;
}

.user-dropdown-content {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  z-index: 1;
  display: none;
}

.user-dropdown-content a {
  color: #464646;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.user-dropdown-content a:hover {
  background-color: #d2d2d2;
}

.entreprise-dropdown-content {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  z-index: 1;
  display: none;
}

.entreprise-dropdown-content a {
  color: #464646;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.entreprise-dropdown-content a:hover {
  background-color: #d2d2d2;
}

.show {
  display: block;
}