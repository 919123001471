.container-modal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 1.8fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  overflow: scroll;
}

.tabs-modal {
  grid-area: 1 / 1 / 2 / 12;
  border: 1px solid #000;
  background-color: #fff;
}

.content-modal {
  grid-area: 2 / 1 / 3 / 12;
  border: 1px solid #000;
  background-color: #fff;
}

.ut {
  grid-area: 1 / 1 / 2 / 2;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.number {
  grid-area: 1 / 2 / 2 / 3;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.responsable {
  grid-area: 1 / 3 / 2 / 4;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.action {
  grid-area: 1 / 4 / 2 / 5;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.risque {
  grid-area: 1 / 5 / 2 / 6;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.open-date {
  grid-area: 1 / 6 / 2 / 7;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.delai {
  grid-area: 1 / 7 / 2 / 8;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.avancement {
  grid-area: 1 / 8 / 2 / 9;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.com {
  grid-area: 1 / 9 / 2 / 10;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.pj {
  grid-area: 1 / 10 / 2 / 11;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.cloture {
  grid-area: 1 / 11 / 2 / 12;
  border: 1px solid #000;
  text-align: center;
  padding: 10px;
}

.ut_content {
  grid-area: 2 / 1 / 3 / 2;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.number_content {
  grid-area: 2 / 2 / 3 / 3;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.responsable_content {
  grid-area: 2 / 3 / 3 / 4;
  border: 1px solid #000;
}

.action_content {
  grid-area: 2 / 4 / 3 / 5;
  border: 1px solid #000;
}

.risque_content {
  grid-area: 2 / 5 / 3 / 6;
  border: 1px solid #000;
}

.open-date_content {
  grid-area: 2 / 6 / 3 / 7;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.delai_content {
  grid-area: 2 / 7 / 3 / 8;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.avancement_content {
  grid-area: 2 / 8 / 3 / 9;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.avancement_select {
  border: 0.5px solid #bababa;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.com_content {
  grid-area: 2 / 9 / 3 / 10;
  border: 1px solid #000;
}

.pj_content {
  grid-area: 2 / 10 / 3 / 11;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.cloture_content {
  grid-area: 2 / 11 / 3 / 12;
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
}

.responsable_input {
  border: 0.5px solid #bababa;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 10px 50px 10px;
}

.cloture_img {
  width: 20px;
  height: 20px;
  margin: 10px;
}

.select_file {
  border: 0.5px solid #bababa;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.select_file:hover {
  background-color: #bcbcbc;
}

.smallFontSize {
  font-size: 0.5rem;
}

