.dashboard {
  scrollbar-width: none;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  background-color: #f5f5f5;
  width: 100%;
}

.dashboard-content h1 {
  font-size: 2rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  margin: 20px;
}

.pageLine {
  margin: 0 20px;
  border: 1.5px solid #999999;
  width: 5%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px;
}

.content-cards {
  background-color: #ffffff;
  box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  min-height: 80px;
  max-height: 100%;
  overflow: scroll;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.input-container p {
  font-weight: 600;
}

.input-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  width: 50%;
}

.input-container input:focus {
  outline: none;
  border-color: #4caf50;
}

.input-container button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.input-container span {
  color: #4caf50;
  font-weight: bold;
  margin-top: 8px;
}
