.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  cursor: pointer;
  width: 100%;
}

.tab {
  background: #cfcfcf;
  border: 0.5px solid #cfcfcf;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  padding: 10px;
  width: 50%;
}

.tabSelected {
  background: #ffffff;
  border: 0.5px solid #cfcfcf;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  padding: 10px;
  width: 50%;
}

.filter-box {
  border-bottom: 1px solid #cfcfcf;
}

.filter-input {
  width: 300px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 16px;
  margin-left: 10px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #cfcfcf;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}

.loading-data {
  margin: 20px;
}

.content-client {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
}

.content-client-icon {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.content-title {
  margin: 0;
  width: 115px;
}

.content-text {
  margin: 0;
  font-size: 14px;
  color: #9b9b9b;
  margin-right: 10px;
  width: 200px;
}

.content-connexion {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.content-text2 {
  margin: 0;
  font-size: 14px;
  color: #9b9b9b;
  margin-right: 5px;
  
}

.content-text3 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-right: 10px;
  width: 75px;
}

.edit-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.export-button {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
  text-decoration: none;
}

.export-button:hover {
  background-color: #43425daa;
}

.highlight-red {
  color: red;
}

.highlight-orange {
  color: orange;
}