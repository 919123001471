.playVideo {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    color: black;
    border: none;
    background: lightgray;
    font-size: 1.2rem;
    padding: 10px;
    width: auto;
    cursor: pointer;
  }
  
  .playVideo:hover {
    background-color: #bbbbbb;
  }
  
  .playVideo img {
    width: 25%;
    margin-right: 10px;
  }
  
  .modalVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: modal-video;
    animation-name: modal-video;
    transition: opacity 0.3s ease-out;
  }
  
  .videoContainer {
    max-width: 940px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: table;
  }
  
  .videoInner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
  
  .videoWrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: modal-video-inner;
    animation-name: modal-video-inner;
    -webkit-transform: translate(0);
    transform: translate(0);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }
  
  .closeVideo {
    position: absolute;
    z-index: 1000001;
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    color: #fff;
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }
  
  .videoWrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  