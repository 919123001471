.client-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.client-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.client-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 50px;
}

.client-img img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 20px;
}

.client-right button {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
}

.client-right button:hover {
  background-color: #ff0000aa;
}

.client-info {
  margin: 0;
}

.client-info-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.client-info-name h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  margin-right: 10px;
}

.client-info h5 {
  font-size: 1rem;
  font-weight: 400;
}

.client-info p {
  font-size: 1rem;
  font-weight: 400;
  color: #9b9b9b;
}

.client-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.client-buttons button {
  padding: 10px 60px;
  border-radius: 10px;
  border: none;
  background-color: #474b6f;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
}

.client-buttons button:hover {
  background-color: #474b6faa;
}

.client-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 20px 0;
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  border: none;
  box-shadow: 24px;
  padding: 15px;
  background-color: #f6f6f6;
  border-radius: 15px;
  overflow: scroll;
}

.modal-style h2 {
  margin: 0;
}
