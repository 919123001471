.affichages {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-left: 2%;
  height: auto;
}

.affichages p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  text-align: left;
}

.affichages-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.affichages-content-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin: 10px 0;
  
}

.boutontelechargement {
  margin: top 20px;
  align-self: true;
}

.affichages-content-tab button {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #43425d;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 10px 20px;
  cursor: pointer;
}

.affichages-content-tab button:hover {
  background-color: #43425daa;
}

.affichages-content-tab-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 40%;
  margin-left: 3%;
}

.affichages-content-tab-right select {
    border: none;
    border-bottom: 1px solid #43425d;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    color: #43425d;
}

.affichages-button {
  background-color: #43425d;
}

.affichages-button:hover {
  background-color: #43425daa !important;
}